// Modal.js
import React from "react";
import "./Modal.css"; // Assuming you have some CSS for styling

interface ModalProps {
  isOpen: boolean; // isOpen is a boolean
  onClose: () => void; // onClose is a function that returns void
  children: React.ReactNode; // children can be any valid React node
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  if (!isOpen) return null;

  return <>{children}</>;
};

export default Modal;
