import {
  Components,
  createBrowserWebSpeechPonyfillFactory,
  createDirectLine,
} from "botframework-webchat";

import React, { memo, useEffect, useState } from "react";

import fetchDirectLineToken from "./fetchDirectLineToken";

import Minimize from "./Minimize";
import Chatform from "./Chatform";
import ChatBot from "./ChatBot";

const { Composer } = Components;

function App() {
  const [webChatProps, setWebChatProps] = useState<
    | Readonly<{
        directLine: ReturnType<typeof createDirectLine>;
        webSpeechPonyfillFactory: ReturnType<
          typeof createBrowserWebSpeechPonyfillFactory
        >;
      }>
    | undefined
  >();

  useEffect(() => {
    (async () =>
      setWebChatProps({
        directLine: createDirectLine({
          token: await fetchDirectLineToken(),
        }),
        webSpeechPonyfillFactory: createBrowserWebSpeechPonyfillFactory(),
      }))();
  }, [setWebChatProps]);

  const [isMinimized, setIsMinimized] = useState(false);

  const handleToggle = () => {
    setIsMinimized(!isMinimized);
  };

  const [toggleStateMini, setToggleStateMini] = useState(false);
  const handleToggleChange = (newToggleState: boolean) => {
    setToggleStateFull(false);
    setToggleStateMini(true);
    console.log("Toggle State app:", newToggleState);
  };

  const [toggleStateFull, setToggleStateFull] = useState(true);
  const handleToggleFullChange = (newToggleState: boolean) => {
    setToggleStateFull(true);
    setToggleStateMini(false);

    console.log("Toggle State app:", newToggleState);
  };
  return (
    <>
      {/* {webChatProps && (
        <Composer {...webChatProps}>
          {toggleStateMini ? (
            <Minimize onToggleChange={handleToggleFullChange} />
          ) : (
            <></>
          )}

          {toggleStateFull ? (
            <Chatform onToggleChange={handleToggleChange} />
          ) : (
            <></>
          )}
        </Composer>
      )} */}

      {webChatProps && (
        <Composer {...webChatProps}>
          <ChatBot />
        </Composer>
      )}
    </>
  );
}

export default memo(App);
